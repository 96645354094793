import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface BannerOneProps {
  eBookTitle: string;
  eBookLabel: string;
  paperbackTitle: string;
  paperbackLabel: string;
  boughtPaperback?: boolean;
  iconUrl: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ecf8f5;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
  max-width: 30.5rem;
  width: 100%;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Img = styled(DynamicImage)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;

const Title = styled(Text)`
  color: #28293d;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: normal;
    margin-bottom: 0.25rem;
  }
`;

const Label = styled(Text)`
  color: #28293d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const BannerOne: FC<BannerOneProps> = ({
  eBookTitle,
  eBookLabel,
  paperbackTitle,
  paperbackLabel,
  boughtPaperback,
  iconUrl,
}) => (
  <Container>
    <InnerContainer>
      <Img src={iconUrl} alt="icon" />
      <TextContainer>
        <Title>{boughtPaperback ? paperbackTitle : eBookTitle}</Title>
        <Label>{boughtPaperback ? paperbackLabel : eBookLabel}</Label>
      </TextContainer>
    </InnerContainer>
  </Container>
);

export default BannerOne;
