import React from 'react';
import styled from 'styled-components';

const CancelBtnContainer = styled.a`
  font-size: 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline !important;
  color: #4d4d4d;
`;

const CancelButton = ({ noButtonTitle, onCancelClick, disabled, ...props }) => (
  <CancelBtnContainer
    onClick={() => {
      if (!disabled) {
        onCancelClick?.();
      }
    }}
    {...props}
  >
    {noButtonTitle}
  </CancelBtnContainer>
);

export default CancelButton;
