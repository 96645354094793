import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import CircleChecked from 'assets/icons/payments/circle-checked.svg';
import CircleUnchecked from 'assets/icons/payments/circle-unchecked.svg';
import theme from 'utils/theme';
import { tablet } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface PaymentStepsProps {
  firstStepTitle: string;
  secondStepTitle: string;
  thirdStepTitle: string;
  bgColor?: string;
  optionalStepTitle?: string;
  hasOptionalStep?: boolean;
}

interface DotDividerProps {
  hasOpacity?: boolean;
}

const Container = styled.div`
  padding: 0.75rem 1rem;
  width: 100%;
`;

const Inner = styled.div`
  max-width: 56.25rem;
  width: 100%;
  display: flex;
  margin: 0 auto;
  @media ${tablet} {
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DotTitle = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.012rem;
  margin-top: 0.25rem;
  @media ${tablet} {
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const DotDividerContainer = styled.div`
  flex: 1;
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
`;

const DotDivider = styled.div<DotDividerProps>`
  flex: 1;
  height: 1px;
  background-color: #ef71a0;
  opacity: ${({ hasOpacity }) => (hasOpacity ? 0.25 : 1)};
`;

const PaymentSteps: FC<PaymentStepsProps & HTMLAttributes<HTMLDivElement>> = ({
  firstStepTitle,
  secondStepTitle,
  thirdStepTitle,
  optionalStepTitle,
  hasOptionalStep,
  ...props
}) => {
  const renderDot = (dot?: string) => {
    switch (dot) {
      case 'tick':
        return <CircleTick fill="#EF71A0" width={24} height={24} />;
      case 'checked':
        return <CircleChecked fill="#EF71A0" width={24} height={24} />;
      default:
        return <CircleUnchecked fill="#EF71A0" width={24} height={24} />;
    }
  };
  const selected_plans_options = useSelector(
    (state: AppState) => state.user.selected_plans_options,
  );

  return (
    <Container {...props}>
      <Inner>
        <StepContainer>
          {renderDot('tick')}
          <DotTitle>{firstStepTitle}</DotTitle>
        </StepContainer>
        <DotDividerContainer>
          <DotDivider />
        </DotDividerContainer>
        <StepContainer>
          {renderDot('checked')}
          <DotTitle>{secondStepTitle}</DotTitle>
        </StepContainer>
        <DotDividerContainer>
          <DotDivider hasOpacity />
        </DotDividerContainer>
        {optionalStepTitle && selected_plans_options?.paperBook ? (
          <>
            <StepContainer>
              {renderDot()}
              <DotTitle>{optionalStepTitle}</DotTitle>
            </StepContainer>
            <DotDividerContainer>
              <DotDivider hasOpacity />
            </DotDividerContainer>
          </>
        ) : null}
        <StepContainer>
          {renderDot()}
          <DotTitle>{thirdStepTitle}</DotTitle>
        </StepContainer>
      </Inner>
    </Container>
  );
};

export default PaymentSteps;
