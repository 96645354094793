import React, { FC } from 'react';
import styled from 'styled-components';

interface ErrorMessageUpgradeProps {
  errorMessage?: string;
}

const ErrorMessageText = styled.p.attrs({ type: 'bodyS' })`
  color: #eb4c49;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.021rem;
  padding: 0.25rem 0;
`;

const ErrorMessageUpgrade: FC<ErrorMessageUpgradeProps> = ({
  errorMessage,
  ...props
}) => {
  if (!errorMessage) return null;
  return <ErrorMessageText {...props}>{errorMessage}</ErrorMessageText>;
};

export default ErrorMessageUpgrade;
