import React, { FC } from 'react';

import styled from 'styled-components';
import { mobile, smMobile, tablet } from 'styles/breakpoints';
import { Loader, Reviews, Text } from 'components';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import DogPawList from '../UpsellList';
import CardList from '../CardList';
import List from '../List';
import Button from '../Button';
import CancelButton from '../CancelButton';
import Disclaimer from 'components/Disclaimer';
import BannerOne from '../BannerOne';
// import DiscountSvg from 'assets/images/upsell/1/discountTag.svg';
import ErrorMessageUpgrade from '../ErrorMessageText';

interface TwoForOneProps {}

const Container = styled.div``;

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f6f4ef;
  @media ${tablet} {
    padding: 1.5rem 2.125rem;
  }
  @media ${smMobile} {
    padding: 1.5rem 0.75rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  max-width: 56.25rem;
  width: 100%;
`;

const ReviewContainer = styled.div`
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 2rem 1rem;
  }
`;

const ListContainer = styled.div`
  width: 100%;
  background-color: #f6f4ef;
  display: flex;
  justify-content: center;
`;

const ListStyledBot = styled(List)`
  max-width: 32rem;
`;

const UpgradeBtnContainer = styled.div`
  width: 100%;
  margin: 1.5rem 0 0.75rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CancelButtonStyled = styled(CancelButton)`
  margin-bottom: 1.5rem;
`;

const TextContainer = styled.div`
  background-color: #f6f4ef;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text)`
  color: #1c1c28;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 130%;
  margin: 1.5rem auto 0.75rem auto;
  max-width: 25.375rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;
const Label = styled(Text)`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0px;
  text-align: center;
  max-width: 33.25rem;
  margin: 0 auto;

  span {
    font-weight: 800;
  }
  @media ${tablet} {
    margin-bottom: 1.5rem;
  }
`;

const DiscountContainer = styled.div`
  max-width: 16rem;
  width: 100%;
  height: 2.875rem;
  border-radius: 0.25rem;
  background: #05a56f;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.62rem;
`;

const StyledText = styled.p`
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
`;

const TwoForOne: FC<TwoForOneProps> = ({
  paymentSteps,
  userHasBoughtPaperbackBook,
  planSection,
  PlanSectionComponent,
  disclaimerSection,
  disabled,
  onUpgradeClick,
  noButtonTitle,
  yesButtonTitle,
  onCancelClick,
  bannerOne,
  title,
  label,
  errorMessage,
  localisedProduct,
}) => {
  const PlanSectionComponentStyled = styled(PlanSectionComponent)`
    padding: 1.25rem 1rem 2.25rem;
  `;

  const renderDiscountPercent = (text: string) => {
    const regex = /\${val}/;
    const savings = localisedProduct?.savings || '';
    const replacedString = text.replace(regex, savings);
    return replacedString;
  };

  return (
    <>
      <BannerOne {...bannerOne} />
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <TextContainer>
        <DiscountContainer>
          {/* <DiscountSvg />{' '} */}
          <StyledText>
            {localisedProduct?.savings || ''}% SPECIAL DISCOUNT{' '}
          </StyledText>
        </DiscountContainer>
        <Title>{renderDiscountPercent(title)}</Title>
        <Label
          dangerouslySetInnerHTML={{ __html: renderDiscountPercent(label) }}
        />
      </TextContainer>
      <PlanSectionComponentStyled {...planSection} />

      <ErrorMessageUpgrade errorMessage={errorMessage} />
      <UpgradeBtnContainer>
        {disabled ? (
          <Loader wrapperHeight={'1'} />
        ) : (
          <Button
            disabled={disabled}
            onClick={onUpgradeClick}
            bgColor="dark100"
          >
            {yesButtonTitle}
          </Button>
        )}
      </UpgradeBtnContainer>
      <CancelButtonStyled
        disabled={disabled}
        onCancelClick={onCancelClick}
        noButtonTitle={noButtonTitle}
      />
      <Disclaimer {...disclaimerSection} />
    </>
  );
};

export default TwoForOne;
