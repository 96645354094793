import React, { FC } from 'react';
import styled from 'styled-components';

interface CardContainerProps {
  bgColor?: string;
  hideCardBorder?: boolean;
  borderColor?: string;
}

const Container = styled.div<{
  bgColor?: string;
  hideCardBorder?: boolean;
  borderColor?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(115, 137, 156, 0.20)'};
  border-radius: 1rem;
  width: 100%;
  margin-bottom: 0.5rem;
  position: relative;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#ffffff')};
  border-width: ${({ hideCardBorder }) => (hideCardBorder ? '0px' : '1px')};
`;

const CardContainer: FC<CardContainerProps> = ({ children, ...props }) => (
  <Container {...props}>{children}</Container>
);

export default CardContainer;
